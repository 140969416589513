.buscador-avanzado {
  margin: 2em;

  .botones {
    .enlace-busqueda-simple {
      margin: 1em;
    }
  }
}

@media (min-width: 529px) {
  .buscador-avanzado {
    .botones {
      .form-group {
        margin-bottom: 0;
        svg {
          margin-right: 5px;
        }
      }
      display: flex;
      flex-direction: row;
      align-items: center;
      .enlace-busqueda-simple {
      }
    }
  }
}
