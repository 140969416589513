footer {
  .contenido-contacto {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .contenido,
    .contacto {
      text-align: center !important;
    }

    .tt-social-icon {
      display: flex;
      justify-content: center;
    }
  }
}
