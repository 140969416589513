.etiqueta-precio {
    margin: 0.5em;
}

.etiqueta-precio-tachado {
  color: red;
  text-decoration: line-through;
  margin: 0.5em;
}

.etiqueta-precio-bruto {
  margin: 0.5em;
}

.etiqueta-precio.manual{
  margin: unset !important;
  margin-left: 0.5em !important;
  margin-right: 0.5em !important;
}