.iconos-articulo-tabla, .iconos-articulo, .iconos-articulo-resumen {
  display: flex;
  justify-content: left;
  > .btn {
    border-radius: 5px;
    border: none;
    display: flex;
    padding: 2%;
    margin: 1%;
    > i {
      margin: unset !important;
    }
  }
  > .catalogo, .catalogo:hover{
    background: unset !important;
    color: $default_color;
    font-weight: 500;
  }
  > .carnet-profesional-existe,
  > .receta-existe {
    background: unset;
    color: green;
  }
  > .carnet-profesional-noexiste,
  > .receta-noexiste {
    background: unset;
    color: red;
  }
  > .carnet-profesional-existe:hover,
  > .receta-existe:hover {
    color: darkgreen;
    background: unset;
  }
  > .carnet-profesional-noexiste:hover,
  > .receta-noexiste:hover {
    color: darkred;
    background: unset;
  }
}

.iconos-articulo {
  margin-bottom: 0.5em;
  > .btn {
    justify-content: left;
    padding: 1% !important;
    width: 33%;
    > svg {
      padding-right: 7px;
    }
  }
  > .catalogo, .catalogo:hover{
    background: unset !important;
    color: $default_color;
    font-weight: 500;
  }
  > .carnet-profesional-existe,
  > .receta-existe {
    background: unset !important;
    color: $default_color;
    font-weight: 500;
    > svg {
      color: green;
    }
  }
  > .carnet-profesional-noexiste,
  > .receta-noexiste {
    background: unset !important;
    color: $default_color;
    font-weight: 500;
    > svg {
      color: red;
    }
  }
  > .carnet-profesional-existe:hover,
  > .receta-existe:hover {
    background: unset !important;
    color: $btn_colorbg_hover;
    > svg {
      color: darkgreen !important;
    }
  }
  > .carnet-profesional-noexiste:hover,
  > .receta-noexiste:hover {
    background: unset !important;
    color: $btn_colorbg_hover;
    > svg {
      color: darkred !important;
    }
  }
}