.atras-listas {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-bottom: 1em;
  svg{
    margin-right: 5px;
  }
}

.listas-compra {
  .tt-title-subpages.noborder {
    padding: 0 !important;
  }

  form#nueva_lista {
    margin-bottom: 2em;
    align-items: baseline;

    .form-group {
      width: 100%;

      input#nombre_lista {
        margin-right: 1em;
        width: 100%;
      }
    }

    button {
      width: 100%;
    }
  }

  .tt-shopcart-table {
    text-align: center !important;
    margin-right: 0;
    .titulos {
      font-weight: bold;
      margin: 0.25;
    }
  }

  .listas_compra_mobile {
    display: block !important;
    .row {
      align-items: center;
    }

    .lista-compra-row {
      margin-top: 1em;
      padding: 0.5em;

      .element-row {
        margin-top: 1em;
      }

      .lista-compra-accion {
        padding: 0.75em;
      }
    }

    .col-sm-12.col-md-12.col-lg-12 {
      > .row {
        //border: 1px solid gray;
      }
    }
  }

  .listas_compra_desctop {
    display: none !important;
    .row {
      align-items: center;
    }
  }
  
}

.listas_compra_detalle_mobile {
  display: block !important;
  .row {
    align-items: center;
  }
  .col-sm-12.col-md-12.col-lg-12 {
    > .row {
      border: 1px solid gray;
    }
  }
  .lista-compra-row {
    padding: 0.75em;
  }
}

.listas_compra_detalle_desctop {
  display: none !important;
  .row {
    align-items: center;
    text-align: center;
    margin-top: 1em;
  }

  .quantity-input.input-cantidad-listas {
    background: #f7f8fa;
    display: block;
    width: 100%;
    height: 50px;
    font-size: 14px;
    line-height: 1em;
    text-align: center;
    border: none;
    outline: none;
    font-family: "Hind", sans-serif;
    font-weight: 500;
    color: #191919;
    border-radius: 6px;
  }
}

.lista-compra-modal {
  .modal-body {
    overflow-y: visible;
    max-height: 500px;
    .tt-title-subpages {
      margin-top: 0;
    }
  }

  .listas_compra_detalle_desctop {
    .quantity-input {
      border: 1px solid $default_color;
    }
  }

  .listas_compra_detalle_mobile {
    .element-row {
      margin: 1em;
      text-align: center;
    }
    .lista-compra-accion {
      padding: 0.75em;
    }
  }

  .tt-shopcart-table {
    text-align: center !important;
    .titulos {
      color: black;
      font-weight: bold;
      margin: 0.25;
    }
  }
}

@media (min-width: 529px) {
  .listas-compra {
    form#nueva_lista {
      margin-bottom: 2em;
      align-items: baseline;
      width: 30%;

      .form-group {
        width: 60%;

        input#nombre_lista {
          margin-right: 1em;
          width: 100%;
        }
      }

      button {
        width: 40%;
      }
    }

    .listas_compra_mobile {
      display: none !important;
    }

    .listas_compra_desctop {
      display: block !important;

      .lista-compra-row {
        margin-top: 1em;
      }
    }
  }

  .listas_compra_detalle_mobile {
    display: none !important;
  }

  .listas_compra_detalle_desctop {
    display: block !important;
  }
}
