.spinner-container {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: $default_color;
  margin: 0.5em;

  animation: spin 1s ease infinite;
}

.btn-plugin {
  .spinner {
    width: 14px;
    height: 14px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
