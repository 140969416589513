.lineas-pedido,
.lineas-presupuesto {
  margin-left: -10px !important;
  margin-right: -10px !important;
}
.tt-shopcart-table {
  .linea-carrito,
  .lineas-presupuesto {
    text-align: center;
    align-items: center;
    div[class^="col-"] {
      padding: 10px;
    }
    .detach-quantity-desctope {
      width: fit-content;
      margin: auto;
    }
    .descripcion {
      align-self: center;
      > .row {
        height: 33%;
      }

      .desc-cod {
        font-weight: bolder;
      }
      .desc-des {
        overflow: hidden;
      }
    }
    .info-lin {
      display: flex;
      .info-cantidad {
        max-width: 80%;
      }
      .botonera-mod {
        max-width: 20%;
        width: 100%;
        display: flex;
        justify-content: center;
        .modificar-obs {
          padding: 2px;
          height: 100%;
          width: 100%;
          > a {
            margin: unset;
          }
        }
      }
    }
    .total {
      text-align: end;
    }
    .eliminar-lin {
      display: flex;
      justify-content: right;
      .btn-borrar-linea {
        border: unset;
        background: unset;
        cursor: pointer;
      }
    }
  }
}
.tt-shopcart-wrapper {
  .mostrar-res {
    display: flex;
    justify-content: center;
    width: 20%;
    border-width: 1px !important;
    border-color: $default_color !important;
    border-radius: 5px !important;
    margin-bottom: 10px;

    text {
      margin-right: 0.5em;
    }
  }
  .mostrar-res:focus {
    display: flex;
  }
}
.tt-shopcart-btn {
  text-align: end;
  margin-bottom: 1em;
  .continuar,
  .borrar,
  .añadir {
    display: flex;
    align-items: center;
  }
  .continuar {
    justify-content: left;
  }
  .borrar {
    justify-content: center;
  }
  .añadir {
    justify-content: right;
  }
  .btn-link {
    border: unset;
    display: flex;
    text {
      margin-left: 0.5em;
    }
  }
  .btn-link:focus {
    display: flex;
  }
}

.totales {
  text-align: start;
  justify-content: start;
  margin-bottom: 2em;

  .total {
    text-align: end;
    margin-right: 0.5em;
    padding-right: 10px;
  }
}

.totales .titulo {
  font-weight: bold;
  color: black;
}

.titulo-lineas-pedido {
}

.titulo-lineas-pedido .tt-title-subpages {
  padding: 0 !important;
  margin-top: 0 !important;
}

.lineas-pedido {
  margin-bottom: 1em;
}
.titulo-lineas-presupuesto {
}
.titulo-lineas-presupuesto .tt-title-subpages {
  padding: 0 !important;
  margin-top: 0 !important;
}

.lineas-presupuesto {
  margin-bottom: 1em;
}

.boton-pedido {
  margin: 0.5em;
}

.tarifa-envio-portes {
  justify-content: right;
  font-weight: bold;
  margin-bottom: 1%;
}
