.mobile-main-menu {
  ul {
    li {
      > ul {
        padding-left: 1em;
      }
    }
  }

  .mm-navbtn-names {
    text-align: end;
  }
}
.tt-desctop-menu{
  .menuitem{
    align-items: center;

    >svg{
      margin-right: 2px;
    }
  }
}
.tt-logo img {
  width: 25%;
  max-height: unset !important;
  height: auto;
}

.tt-badge-news {
  background: $header_cart_badge_bg;
  @include border-radius(50%);
  color: $header_cart_badge_text;
  font-size: 10px;
  //left: 5px;
  padding-left: 5px;
  padding-right: 5px;
  pointer-events: none;
  position: relative;
  right: 10px;
  top: -1px;
}

.tt-desctop-parent-cart {
  padding-right: 5px;
}

.tt-desctop-parent-account {
  > .tt-account {
    > .mi-cuenta {
      display: inline-flex;
      align-items: center;
      svg{
        margin-right: 2px;
      }
    }
    >.sub-mi-cuenta{
      a{
        display: inline-flex;
      }
      svg{
        margin-right: 4px;
      }
    }
  }
}
