.login form .form-group {
  display: block;
}

.login form .form-control {
  -webkit-border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomright: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.login form .btn {
  -webkit-border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.login .tt-item {
  border: none;
}

.link-rec-pass {
  color: #f0ab00;
}

.link-registro {
  color: #f0ab00;
}
