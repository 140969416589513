.form-body{
    display: flex;
    justify-content: center;
    .form-default{
        width: 60% !important;
    }
    .form-group.file{
        display: block;
        label{
            width: 100%;
        }
    }
}