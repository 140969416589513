.expedientes {
  .btn-primary {
    margin-left: 5px;
    margin-bottom: 3px;
  }

  .tt-shopcart-table {
    text-align: center !important;
    .titulos {
      font-weight: bold;
      margin: 0.25;
    }
  }

  .tipos_expedientes_mobile {
    display: block !important;
    .row {
      align-items: center;
    }
    .col-sm-12.col-md-12.col-lg-12 > .row {
      border: 1px solid gray;
    }
  }
  .tipos_expedientes_desctop {
    display: none !important;
    .row {
      align-items: center;
    }
  }
}
.contenedor_expedientes {
  .indicador {
    width: 100%;
    display: flex;
    justify-content: center;
    p {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
.expediente {
  .base-expediente {
    display: block;
    width: 100%;
    .acciones-expediente {
      display: flex;
      justify-content: right;
      width: 100%;
      .btn-volver-listado,
      .btn-cerrar-exp {
        margin: 5px;
        padding: 5px;
        display: flex;
        flex-direction: row;
        border-radius: 4px;
        border: unset;
        align-items: center;
        justify-content: center;
        > svg {
          margin-right: 3px;
        }
         > span {
          height: 20px;
         }
      }
      .btn-volver-listado {
        background-color: $default-color;
        color: $default_bg_color;
      }
      .btn-cerrar-exp {
        background-color: $errors_bg;
        color: black;
      }
      .btn-cerrar-exp:disabled {
        background-color: $color-bg-disabled;
      }
      .btn-volver-listado:hover,
      .btn-cerrar-exp:hover {
        cursor: pointer;
      }
    }
    .numero {
      font-size: 16px;
    }
    .numero,
    .status,
    .tipo {
      width: 33%;
      display: flex;
    }
    .titulo {
      width: 50%;
      font-weight: bold;
    }
    .valor {
      width: 50%;
    }
  }

  .titulos {
    background-color: $default-color;
    color: $default_bg_color;
    font-weight: bold;
    display: flex;
    .titulo-campo {
      display: flex;
      justify-content: center;
    }
  }
  .datos {
    display: flex;
    border-left: 1px solid $border;
    .valor-campo {
      padding: 3px;
      border-bottom: 1px solid $border;
      border-right: 1px solid $border;
    }
  }
}
.menu-expedientes {
  .esquema-color {
    background-color: $default-color;

    li {
      display: flex;
      width: fit-content;
      height: 3em;
      padding-left: 4px;
      padding-right: 4px;
      a {
        display: flex;
        align-items: center;
        color: $default_bg_color;
        font-size: 14px;
      }
    }
    li:hover {
      a {
        color: black;
      }
    }
  }
}
.ordenar {
  display: flex;
  justify-content: right;
  align-items: center;

  #label-select-orden {
    margin-right: 3px;
    font-weight: bold;
  }
  #label-select-resultados {
    margin-right: 3px;
    font-weight: bold;
  }
  select {
    border-color: transparent;
    outline: none;
    border: none;
    background: transparent;
  }
}
.botonera-mas {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}
.acciones-expedientes {
  display: flex;
  justify-content: left;
  width: 100%;
  align-items: center;
  .btn-add-expediente {
    margin: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    border: unset;
    align-items: center;
    justify-content: center;
    background-color: $default-color;
    color: $default_bg_color;
    > svg {
      margin-right: 3px;
    }
    > span {
      height: 20px;
    }
  }
  .btn-add-expediente:hover {
    cursor: pointer;
  }
}
.listado_expedientes {
  display: flex;
  flex-wrap: wrap;
  .expediente {
    .tarjeta {
      box-shadow: 0px 0px 5px 2px $color-box-shadow;
      text-align: center;
      margin-top: 1em;
      margin-left: 0.5em;
      margin-right: 0.5em;
      .titulo {
        background-color: $default-color;
        color: $default_bg_color;
        padding: unset;
        display: flex;
        .numero_exp,
        .fecha_alta {
          width: 50%;
          display: flex;
          font-weight: bold;
        }
        .fecha_alta {
          justify-content: right;
          padding-right: 3px;
        }
        .numero_exp {
          justify-content: left;
          padding-left: 3px;
          font-size: 16px;
        }
      }
      .iconos_informativos {
        min-height: 20px;
        .tiene_ficheros {
          display: flex;
          justify-content: flex-end;
          > svg {
            margin-right: 3px;
            margin-top: 3px;
          }
        }
      }

      .campos {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .campo {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          width: 100%;
          margin-bottom: 0.5em;
          .literal {
            text-align: left;
            padding-left: 0.5em;
            > h6 {
              padding: unset;
            }
          }
        }
      }
    }
    .tarjeta:hover {
      box-shadow: 0px 0px 5px 2px $color-box-shadow-hover;
      cursor: pointer;
    }
  }
  .tt-product.thumbprod-center:hover {
    .tt-product-inside-hover {
      display: contents !important;
    }
  }
}
.detalle-expediente {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
  .campo {
    max-width: 33%;
    min-width: 200px;
    width: fit-content;
    .titulo-campo,
    .valor-campo {
      width: 100% !important;
      margin-right: 4px;
    }
    .titulo-campo {
      padding-left: 2px;
      background-color: $default-color;
      color: $default_bg_color;
      justify-content: center;
      font-weight: bold;
    }
    .valor-campo {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 2px;
    }
  }
}
.detalle-ficheros {
  display: flex;
  flex-wrap: wrap;
  justify-items: left;
  .tarjeta-fichero {
    display: flex;
    flex-wrap: wrap;
    width: 20%;
    justify-content: center;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 0px 5px 0px $color-box-shadow;

    .tipo {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 40px;
      align-items: center;
      font-size: 40px;
      padding: 4px;
      margin: 4px;
    }
  }
  .tarjeta-fichero.pdf {
    background-color: red;
    color: $default_bg_color;
  }
  .tarjeta-fichero.csv,
  .tarjeta-fichero.xls,
  .tarjeta-fichero.ods {
    background-color: green;
    color: $default_bg_color;
  }
  .tarjeta-fichero.jpg,
  .tarjeta-fichero.jpeg,
  .tarjeta-fichero.mpeg {
    background-color: orangered;
    color: $default_bg_color;
  }
  .tarjeta-fichero.doc {
    background-color: $default_color;
    color: $default_bg_color;
  }
  .tarjeta-fichero.zip {
    background-color: purple;
    color: $default_bg_color;
  }

  .tarjeta-fichero:hover {
    box-shadow: 0px 0px 5px 0px $color-box-shadow-hover;
  }
}
.titulo-listado-expedientes {
  font-size: 34px;
  line-height: 44px;
  font-weight: 500;
  letter-spacing: 0.03em;
  width: 100%;
  display: flex;
  justify-content: center;
}

.titulo-ficheros {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: 0.03em;
  width: 100%;
  display: flex;
  justify-content: left;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

@media (min-width: 529px) {
  .expedientes {
    .tipos_expedientes_mobile {
      display: none !important;
    }
    .tipos_expedientes_desctop {
      display: block !important;
    }
  }
}
