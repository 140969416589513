.tt-shopcart-table .linea-carrito {
  align-items: baseline;
}

.tt-shopcart-table .linea-carrito div[class^=col-]{
  padding: 10px;
}


.tt-shopcart-table .linea-carrito .detach-quantity-desctope{
    width: fit-content;
    margin: auto;
}