.prueba {
  background: #f7f8fa;
  display: block;
  width: 100%;
  height: 50px;
  font-size: 14px;
  line-height: 1em;
  text-align: center;
  border: none;
  outline: none;
  font-family: "Hind", sans-serif;
  font-weight: 500;
  color: #191919;
  border-radius: 6px;
}

.prueba::placeholder {
  opacity: 0.4;
}

.lista-plantilla-row {
  align-items: center;
}

.lista-plantilla-row div {
  text-align: center;
  padding: 4px;
}

.btn-crear-pedido-plantilla {
  margin: auto;
  width: 100%;
  margin-top: 0px;
}

.spinner-holder {
  scale: 0.5;
  padding: 05px;
}

.messages-titles-plantilla {
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  font-weight: 500;
  padding: 5px;
  border-bottom: 1px solid rgba(122, 122, 122, 0.486);
  margin-bottom: 10px;
}

.messages-titles-plantilla .exit-holder {
  display: flex;
  text-align: right;
  justify-items: right;
  justify-content: right;
  cursor: pointer;
  transition: 0.3s;
}

.alert-holder {
  border: 1px solid rgba(122, 122, 122, 0.486);
  margin-bottom: 20px;
}

.messages-titles-plantilla .exit-holder:hover {
  color: rgb(255, 125, 125);
}

.plantilla-vacia {
  display: block;
  font-size: 1.3em;
  text-align: center;
  margin-top: 35px;
}

.cart-warn-plantillas {
  opacity: 0;
  display: block;
  margin-top: 9px;
  transition: 0.3s;
  text-align: center;
  color: rgb(245, 126, 47);
  font-weight: 500;
}

.btn-holder:hover ~ .cart-warn-plantillas {
  opacity: 1;
}

.atras-listas {
  margin: 0;
}

.plantilla-details {
  margin: 20px 0px;
}

.plantilla-details span.separator {
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 4000px;
  background: rgb(235, 235, 235);
  margin: 9px;
}

.plantilla-details .details-col {
  display: flex;
  text-align: center;
  margin: 5px 0px;
}

.plantilla-details .details-col span {
  display: inline;
  font-size: 1.1em;
  padding: 3px 10px;
}

.plantilla-details .row {
  margin: 15px 0px;
  text-align: center;
}

.plantilla-details .details-col .title {
  font-weight: 900;
}

.plantilla-tag {
  background: #505050;
  color: #303030;
  font-weight: 500;
  /* border-radius: 5px; */
  padding: 3px 17px;
  color: white;
  margin: 0;
}

.plantilla-tag.info {
  background: rgb(68, 148, 253);
}

.plantilla-tag.success {
  background: rgb(79, 163, 68);
  color: white;
}

.plantilla-tag.error {
  background: rgb(248, 109, 104);
  color: white;
}

.plantilla-tag.warn {
  color: rgb(248, 137, 62);
  background: transparent;
}

.plantilla-tag.blank {
  background: transparent;
  color: #2b2b2b;
}

.plantilla-details .external-items {
  margin-top: 25px;
  margin-bottom: 10px;
  text-align: center;
}
