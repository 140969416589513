.tabla-subscripciones {
  .tabla_subscripciones_mobile {
    display: block !important;
    .row {
      align-items: center;
    }

    .col-sm-12.col-md-12.col-lg-12 {
      > .row {
        border: 1px solid gray;
        .titulos {
          font-weight: bold;
        }
        .minimo_m,
        .multiplo_m,
        .descuento_m,
        .precio_m {
          text-align: center;
        }
      }
    }
  }

  .tabla_subscripciones_desctop {
    display: none !important;
    .row {
      align-items: center;
    }
  }
}

@media (min-width: 1020px) {
  .tabla-subscripciones {
    .tabla_subscripciones_mobile {
      display: none !important;
    }

    .tabla_subscripciones_desctop {
      display: block !important;
      .titulos {
        background-color: $default_color;
        color: white;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
      }
      .titulo-accion {
        text-align: left;
      }
      .aviso-unico-check{
        margin-right: 10%;
      }
      .status_act, .status_des {
        display: inline-flex;
        border: 1px solid;
        border-radius: 3px;
        width: 50%;
        text-align: center;
        font-weight: bold;
        justify-content: center;
      }
      .status_act {
        background-color: lawngreen;
      }
      .status_des{
        background-color:lemonchiffon
      }
      .eliminar-subscripcion-btn, .reactivar-subscripcion-btn{
        padding-left: 5% !important;
        padding-right: 5% !important;
        margin: 1%;
        >svg{
            margin: unset;
        }
      }
      .articulo,
      .descripcion,
      .acciones {
        text-align: left;
      }
    }
    .registro-subscripciones {
      padding-top: 1em;
      padding-bottom: 1em;
      text-align: center;
    }
    .registro-subscripciones:nth-child(even) {
      background: #f7f8fa;
    }
  }
}
