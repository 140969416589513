.fecha_de_entrega {
    .fecha_de_entrega_container {
        display: flex;
        .fecha_de_entrega_excepciones {
            margin-left: 20px;
            h6 {
                padding: 0;
            }
            .excepcion_fecha {
                font-weight: bold;
            }
        }
    }
}