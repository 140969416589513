.mi-cuenta {
  h1.tt-title-subpages.noborder {
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.03em;
    font-weight: 500;
    padding: 0 0 50px 0;
    margin-top: -10px;
  }

  h3.tt-title{
    font-size: 22px !important;
    padding-top: 10px !important;
  }

  .tt-wrapper{
    margin-top: 10px !important;
  }

}
.btn-border.subscripciones{
  margin-bottom: 10px;
}
