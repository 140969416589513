.efacturas-ventas {
  .formulario-efactura {
    .dropzone {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border-width: 2;
      border-radius: 2;
      border-color: #eeeeee;
      border-style: dashed;
      background-color: #fafafa;
      color: #bdbdbd;
      outline: none;
      transition: border 0.24s ease-in-out;
    }

    .enviar,
    .cancelar {
      margin: 0.5em;
    }

    .lista-ficheros-seleccionados {
      list-style: none;
    }
  }

  .lista-efacturas {
    .tt-shopcart-table {
      text-align: center !important;
      .titulos {
        font-weight: bold;
        margin: 0.25;
      }
    }

    .efactura_ventas_desctop {
      display: none;
    }

    .efacturas_ventas_mobile {
      display: block;
      .row {
        align-items: center;
      }

      .efactura-ventas-row {
        margin-top: 1em;
        padding: 0.5em;

        .element-row {
          margin-top: 1em;
        }
      }
    }
  }
}

@media (min-width: 529px) {
  .efacturas-ventas {
    .lista-efacturas {
      .efactura_ventas_desctop {
        display: block;

        .efactura-ventas-row {
          margin-top: 1em;
        }
      }

      .efacturas_ventas_mobile {
        display: none;
      }
    }
  }
}
