.buscador {
  margin: 2rem;
  .input-group-append {
    .btn {
      z-index: 0;
      svg{
        margin-right: 5px;
      }
    }
  }

  .enlace-busqueda-avanzada{
    margin: 1em;
  }
}
