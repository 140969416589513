.login {
  form {
    .form-group {
      display: block;
      .form-control {
        -webkit-border-top-right-radius: 6px;
        -webkit-border-bottom-right-radius: 6px;
        -moz-border-radius-topright: 6px;
        -moz-border-radius-bottomright: 6px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
    .btn {
      -webkit-border-top-left-radius: 6px;
      -webkit-border-bottom-left-radius: 6px;
      -moz-border-radius-topleft: 6px;
      -moz-border-radius-bottomleft: 6px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
  .tt-item {
    border: none !important;
  }

  .link-rec-pass {
    color: $default_color;
  }

  .link-registro {
    color: $default_color;
  }
  @media only screen and (min-width: 1025px) {
    .bg_login {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100vh;
      width: auto;
    }
  }

  @media only screen and (max-width: 1025px) {
    .bg_login {
      position: fixed;
      height: 100vh;
      width: auto;
      z-index: -1;
      top: 0;
      
      display: block;
      // visibility: hidden;
      overflow-x: hidden;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}
