.form-control.desctop {
  display: none;
}
.mobile {
  display: block;
}

.desctop {
  display: none;
}

.control_anadir_carrito_ficha {
  width: 100%;
  .form-control:not(textarea) {
    height: 50px !important;
  }
  .botones-secundarios {
    justify-content: left;
    width: 100%;
    .tt-wrapper {
      width: 100%;
      .tt-list-btn {
        display: flex;
        justify-content: left !important;
        li {
          margin: 0;
        }
      }
    }
  }
  .btn, .btn-link{
    svg{
      margin-right: 3px;
    }
  }

  .btn-link {
    border: none;
    display: inline-flex;
  }
  .btn-link:focus {
    display: inline-flex;
  }

  .input-group {
    width: 100%;
    flex-direction: row;
    justify-content: left;

    .detach-quantity-desctope {
      width: 100%;

      .tt-input-counter {
        width: 100%;
        max-width: unset;
      }
    }
    .btn {
      margin-top: 0.5em;
      height: 50px !important;
      width: 100%;
    }
  }

  .input-group-append {
    width: 100%;
    button {
      width: 100%;
    }
  }
}

.control_anadir_carrito_tabla {
  // Sobreescriben la estética predefinida del los botones eliminando el margin del icono
  .btn:not(.tt-icon-right) {
    @media (min-width: 576px) {
      [class^="icon-"] {
        margin-right: 0px !important;
      }
    }
    @media (max-width: 575px) {
      [class^="icon-"] {
        margin-right: 0px !important;
      }
    }
  }
  .input-group {
    display: flex;
    justify-content: center;
    width: 100% !important;
    .form-control {
      max-width: 40%;
      margin-right: 5px;
      border: 1px solid $default_color;
      border-radius: 5px !important;
      text-align: center;
    }
    .detach-quantity-desctope.mobile {
      display: flex;
      justify-content: center;
      padding: 0px !important;
      margin-bottom: 5px;
      .tt-input-counter {
        width: 100%;
        max-width: unset !important;
      }
    }
    .botonera {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .btn {
        padding: 0.5em !important;
        margin-left: 1%;
        min-width: 32%;
        width: inherit;
        max-width: 48%;
        text-align: center;
      }
    }
  }
}
.control_anadir_carrito {
  .btn, .btn-link{
    svg{
      margin-right: 3px;
    }
  }
  .detach-quantity-desctope.mobile {
    width: 100%;
    .tt-input-counter {
      max-width: 100%;
    }
  }
  .btn.btn-primary {
    width: 100%;

  }
}

.tabla_agrupacion {
  .control_anadir_carrito {
    width: 100%;
  }
}

@media (min-width: 529px) {
  .mobile {
    display: none !important;
  }

  .desctop {
    display: block !important;
  }

  .control_anadir_carrito {
    display: flex !important;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;
    .detach-quantity-desctope {
      width: 100%;

      .tt-input-counter {
        width: 100%;
        max-width: unset;
      }
    }

    .form-control {
      width: 100%;
      border: 1px solid $default_color;
    }

    .input-group-append {
      width: 100%;
      button {
        width: 100%;
      }
    }

    .input-group {
      width: 100%;
      justify-content: center !important;

      .form-control {
        width: 25%;
        max-width: 25%;
        border-radius: unset;
        border-bottom-left-radius: 5px !important;
        border-top-left-radius: 5px !important;
      }

      .input-group-append {
        width: 50%;
      }

      .mobile {
        display: none !important;
      }
      .desctop {
        display: flex !important;
      }

      .btn {
        padding: 0.5em !important;
        width: 50%;
        border-radius: unset;
        border-bottom-right-radius: 5px !important;
        border-top-right-radius: 5px !important;
      }
    }
  }

  .control_anadir_carrito_tabla {
    .input-group {
      .form-control {
        width: 15%;
      }
    }
    .botonera {
      width: 60% !important;
      .btn {
        min-width: 20%;
      }
    }
  }

  .control_anadir_carrito_ficha {
    .input-group {
      width: 100%;
      .form-control {
        width: 20%;
        border-radius: 10px !important;
      }

      .desctop {
        display: flex !important;
      }
      .mobile {
        display: none !important;
      }

      .input-group-append {
        width: 50%;
      }

      .btn {
        margin-left: 1em !important;
        margin-right: 0 !important;

        width: 70%;
        margin-top: unset;
      }
    }
  }
}

@media (min-width: 576px) {
  .control_anadir_carrito {
  }

  .control_anadir_carrito_ficha {
    .form-control {
      text-align: center;
    }
  }

  .control_anadir_carrito_tabla {
  }
}
