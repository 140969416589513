.iconos-funcion {
  display: flex;
  justify-content: left;
  margin-bottom: 0.5em;
  > .btn {
    justify-content: left;
    font-weight: 500;
    padding: 1% !important;
    background: unset;
    color: $default_color;
    border-radius: 5px;
    border: none;
    display: flex;
    margin: 1%;
    > svg {
      padding-right: 7px;
    }
  }
  > .btn:hover {
    background: unset;
    color: $btn_colorbg_hover
  }
}
.subs,
.list {
  margin-left: 1em;
  border: unset;
}
.tt-btn-wishlist{
  border: unset;
  display: none;
  >span{
    border: unset;
  }
}

@media (max-width:370px){
  .tt-btn-wishlist{
    display: block;
  }
}