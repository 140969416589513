.buscador-rapido {
  width: 40% !important;
  position: relative;

  .label_busc_rap {
    font-size: medium;
    font-weight: bold;
    width: 100%;
    text-align: left;
  }

 .input-buscador-rap {
     width: 100%;
     display: flex;
     
     .inp-busc-rap {
       width: 90%;
       border-top-right-radius: 0%;
       border-bottom-right-radius: 0%;
     }
     .busc-rap-btn {
       width: 10%;
       border-top-left-radius: 0%;
       border-bottom-left-radius: 0%;

       >i{
        margin: unset !important;
       }
     }
  }

  .listado-buscador {
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
    max-height: 20em;
    margin-top: 1em;
    position: absolute;
    z-index: 2;

    .articulo-busc-rap {
      margin: unset !important;
      padding-top: 0.5em !important;
      padding-bottom: 0.5em !important;
      justify-content: center;
      border: 1px solid gray;
      opacity: 1;
      background-color: white;
    }
    .articulo-busc-rap-dis {
      margin: unset !important;
      padding-top: 0.5em !important;
      padding-bottom: 0.5em !important;
      justify-content: center;
      background-color: lightgray;
      border: 1px solid gray;
      opacity: 1;
    }
  }
  .articulo-busc-rap-helper {
    width: 100%;
    display: block !important;
    margin-top: 1em;
    position: absolute;
    z-index: 2;
  }
  .articulo-busc-rap-loader {
    width: 100%;
    display: block !important;
    position: absolute;
    z-index: 2;
  }
}
