.familias-menu-imagenes {
  .imagen-familia {
    cursor: pointer;
    min-width: 20%;
    min-height: 100px;
    height: auto;
    background-position: center;
    margin: 0.5em;
    text-align: center;
    display: flex;
    padding-left: 0;
    padding-right: 0;

    .overlay {
      background-color: $default_color;
      width: 100%;
      height: 100%;
      position: absolute;
      mix-blend-mode: color;
    }

    .texto-familia {
      margin: auto;
      color: white;
      font-size: x-large;
      font-weight: bold;
    }
  }
}

.menu-familias {
  .tt-desctop-parent-menu.tt-parent-box {
    width: 100%;
    .tt-desctop-menu {
      width: 100%;
      nav {
        .toggle-familias-div {
          display: block;
          text-align: center;
          padding: 1em;
          .toggle-familias-button {
            color: white;
          }
        }
        ul.familias-primer-nivel {
          display: none;
        }
        ul.familias-primer-nivel.visible {
          display: flex;
        }

        ul {
          justify-content: space-around;
          flex-direction: column;

          .tt-submenu {
            .dropdown-menu {
              display: none !important;
            }
          }
          .tt-submenu:hover {
            .dropdown-menu {
              z-index: 1;
              padding-top: 10px;
              padding-left: 5px;
              padding-bottom: 5px;
              display: block !important;
              width: 140px;
              > .tt-col-list {
                margin-right: unset !important;
                margin-left: unset !important;
                > .col {
                  padding-right: unset !important;
                }
              }
              .tt-megamenu-submenu {
                width: 100%;
                > li {
                  width: 100%;
                  > a {
                    width: 100%;
                  }
                  .tt-nivel-1 {
                    display: none !important;
                    margin: unset !important;
                    padding-top: 5px;
                    padding-left: 5px;
                    padding-bottom: 5px;
                    .tt-submenu-1 {
                      display: none !important;
                      > li {
                        > a {
                          padding-left: unset !important;
                        }
                      }
                    }
                  }
                }
                li:hover {
                  .tt-nivel-1 {
                    display: block !important;
                    position: absolute;
                    left: 115px;
                    top: 1px;
                    .tt-submenu-1 {
                      display: contents !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 529px) {
  .menu-familias {
    .tt-desctop-parent-menu.tt-parent-box {
      .tt-desctop-menu {
        nav {
          .toggle-familias-div {
            display: none;
          }
          ul.familias-primer-nivel.visible {
            display: flex;
          }

          ul.familias-primer-nivel {
            display: flex;
          }

          ul {
            flex-direction: row;
          }
        }
      }
    }
  }
}
