.tt-product-listing.row {
  .tt-product.thumbprod-center {
    height: 100%;
    .tt-image-box {
      width: 100%;
      height: 50%;
      background-color: $default_bg_color;

      img {
        max-height: 300px;
        max-width: 100%;
        top: 50%;
        left: 50%;
        margin-top: 25%;
        width: 200px;
        margin-bottom: 25%;
      }
    }

    .tt-description {
      height: 50%;
    }
  }
}

@media (min-width: 529px) {
  .tt-product-listing.row {
    .tt-product.thumbprod-center {
      .tt-image-box {
        height: 60%;
      }
      .tt-description {
        height: 40%;
      }
    }
  }
}
