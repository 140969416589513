.registro-hijos {
  .btn-primary{
    margin-bottom: 2px;

  }
  .nuevo-hijo-row {
    padding: 2em;
  }

  .tt-shopcart-table {
    text-align: center !important;
    .titulos {
      font-weight: bold;
      margin: 0.25;
    }
  }

  .lista_hijos_mobile {
    display: block !important;
    .row {
      align-items: center;
    }

    .col-sm-12.col-md-12.col-lg-12 {
      > .row {
        border: 1px solid gray;
      }
    }
  }

  .lista_hijos_desctop {
    display: none !important;
    .row {
      align-items: center;
    }
  }
}

@media (min-width: 529px) {
  .registro-hijos {
    .lista_hijos_mobile {
      display: none !important;
    }

    .lista_hijos_desctop {
      display: block !important;
    }
  }
}
